import React, { useRef, useState } from 'react';
import { Table, Accordion, Icon, Input, Checkbox, Radio } from 'semantic-ui-react';

class MainSidebar extends React.Component {

  // Mobile friendly, sidebar collapse or bottom bar
  // Search should bring up different assets on the map as a list, clicking on them moves user and highlights them
  // "reset" button that resets the view to default start

  constructor(props) {
    super(props);
    this.state = {
      activeTab : false,
      openMenu : window.innerWidth < 768 ? false : true,
      openMeasurementDetails : false
    }
  }

  toggleMeasurement = (measurementType) => {
    if(window.innerWidth < 768) {
      this.setState({openMenu: false, openMeasurementDetails : true})
    }
    this.props.toggleMeasurement(measurementType);
  }

  render() {
    const { activeTab, openMenu } = this.state;

    return (
      <div>
        <div className="custom-sidebar-container" onClick={(e) => e.stopPropagation()} onScroll={(e) => e.stopPropagation()}>
          <div className="custom-sidebar">
            <div className="menu-branding">
              <div style={{width:'20%'}}>
                <img src='./img/ig-icon.png' className="App-logo" alt="logo" />
              </div>
              <div style={{width:'80%'}}>
                <h3>Integrated Geomatics</h3>
                <small>Digital Twin Asset Mapping & Management</small>
              </div>
              {window.innerWidth < 768 ?
                <div>
                  <Icon style={{marginTop:'22px', fontSize:'30px'}} onClick={() => this.setState({openMenu : !openMenu})} name='bars' />
                </div>
              : false }
            </div>
            <div className="main-menu" style={{display : openMenu ? 'block' : 'none'}}>
              <Accordion styled>
                {/*<Accordion.Title active={activeTab==='search'} index={0} onClick={() => this.setState({activeTab: activeTab==='search' ? false : 'search'})}>
                  <Icon name="dropdown" /> Search / Query
                </Accordion.Title>
                <Accordion.Content active={activeTab==='search'}>
                  <Input placeholder="Search..." />
                </Accordion.Content>*/}

                <Accordion.Title active={activeTab==='tools'} index={1} onClick={() => this.setState({activeTab: activeTab==='tools' ? false : 'tools'})}>
                  <Icon name="dropdown" /> Tools
                </Accordion.Title>
                <Accordion.Content active={activeTab==='tools'}>
                  <div id="tools">
                    {this.props.measurementTypes.map(measurementType => {
                      return (
                        <div key={measurementType} id={"measure_"+measurementType}>
                          <button onClick={() => this.toggleMeasurement(measurementType===this.props.selectedMeasurement ? false : measurementType)} className={"ui labeled icon button " + (this.props.selectedMeasurement === measurementType ? 'active' : '')}>
                            <img style={{padding:'10px'}} className="icon" src={"./img/"+measurementType+".png"} />
                            {measurementType}
                          </button>
                        </div>
                      )
                    })}
                    <Table unstackable>
                      {this.props.currentMeasurements.map((measurement, index) => {
                        var text = measurement.data.filter(item => item.text_element);
                        return (
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>{measurement.name}</Table.Cell>
                              <Table.Cell>{text[0].text_element.innerHTML}</Table.Cell>
                              <Table.Cell><img onClick={() => this.props.removeMeasurement(index)} style={{height: '10px', cursor: 'pointer'}} className="icon" src="./img/delete.png" /></Table.Cell>
                            </Table.Row>
                            <Table.Row active>
                              <Table.Cell>Elev. Diff</Table.Cell>
                              <Table.Cell>{text[0].text_element.elevation_change}</Table.Cell>
                              <Table.Cell></Table.Cell>
                            </Table.Row>
                            <Table.Row active>
                              <Table.Cell>{text[0].text_element.start_point}</Table.Cell>
                              <Table.Cell>{text[0].text_element.end_point}</Table.Cell>
                              <Table.Cell></Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        )
                      })}
                    </Table>
                  </div>
                </Accordion.Content>

                <Accordion.Title active={activeTab==='layers'} index={0} onClick={() => this.setState({activeTab: activeTab==='layers' ? false : 'layers'})}>
                  <Icon name="dropdown" /> Layers
                </Accordion.Title>
                <Accordion.Content active={activeTab==='layers'}>
                  {/* Object.keys(this.state.raster_layers).map(function(keyName, keyIndex) {
                    return (
                      <div key={"checkbox-"+keyIndex}>
                        <input className="raster-toggle" type="checkbox" value={keyName} /> {keyName} <br />
                      </div>
                    )
                  }) */}
                  <p><strong>Rasters</strong></p>
                  {this.props.skins.map((skin, index) => {
                    return (
                      <div key={"skin-"+index}>
                        <Radio onChange={() => this.props.toggleSkin(skin.name)} value={skin.name} checked={skin.visible} label={skin.name} />
                      </div>
                    )
                  })}
                  {this.props.imageLayers.map((imageLayer, index) => {
                    return (
                      <div key={"imageLayer-"+index}>
                        <Checkbox onChange={() => this.props.toggleImageLayer(imageLayer.name)} value={imageLayer.name} checked={imageLayer.visible} label={imageLayer.name} />
                      </div>
                    )
                  })}
                  <br />
                  <p><strong>Shapefiles</strong></p>
                  {this.props.geojsons.map((geojson, index) => {
                    return (
                      <div key={"geojson-"+index}>
                        <Checkbox onChange={() => this.props.toggleGeoJSON(geojson.filename)} value={geojson.filename} checked={geojson.visible} label={geojson.name} />
                      </div>
                    )
                  })}
                </Accordion.Content>

                <Accordion.Title active={activeTab==='docs'} index={0} onClick={() => this.setState({activeTab: activeTab==='docs' ? false : 'docs'})}>
                  <Icon name="dropdown" /> Documents
                </Accordion.Title>
                <Accordion.Content active={activeTab==='docs'}>
                  <table className="ui celled striped table">
                    <tbody>
                      {this.props.files.map(thisFile => {
                        var fileLink = thisFile.file.indexOf('http') === -1 ? thisFile.file.split('/')[thisFile.file.split('/').length-1] : thisFile.file.split('/')[2];
                        return (
                          <tr key={thisFile.name}>
                            <td>{thisFile.name}</td>
                            <td><a href={thisFile.file}>{fileLink}</a></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </Accordion.Content>
              </Accordion>
            </div>
          </div>
        </div>
        {this.state.openMeasurementDetails ?
          <div className="mobile-measurement-details" onClick={() => this.setState({openMenu:true, openMeasurementDetails : false})}>
            Touch the map to measure. Touch here to see measurement details.
          </div>
        : false}
      </div>
    );
  }
}

export default MainSidebar;
