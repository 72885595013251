import React, { useRef, useState } from 'react';
import _ from 'underscore';
import proj4 from 'proj4';
import * as turf from '@turf/turf';
import { MeshLine, MeshLineMaterial } from 'threejs-meshline';
const THREE = window.THREE;

var geoJSONGroups = [];

class Scene extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      scene : false,
      camera : false,
      renderer : false,
      raycaster : false,
      dragging : false,
      textures : [],
      currentGroupBeingDrawn : [],
      measurementBeingDrawn : false,
      mainPlane : false,
      wirePlane : false,
      geolocatedCircles : []
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { scene, mainPlane, wirePlane } = this.state;

    if(!_.isEqual(prevProps.geojsons, this.props.geojsons)) {
      this.props.geojsons.forEach(geojson => {
        var thisObjectSet = geoJSONGroups.filter(group => group.groupName === geojson.filename)
        if(thisObjectSet.length > 0) {
          thisObjectSet[0].objects.forEach(object => {
            object.visible = geojson.visible;
          })
        }
      })
    }

    if(!_.isEqual(prevProps.currentMeasurements, this.props.currentMeasurements)) {
      prevProps.currentMeasurements.forEach(measurement => {
        var found = false;
        this.props.currentMeasurements.forEach(thisMeasurement => {
          if(measurement.id === thisMeasurement.id) {
            found = true;
          }
        })
        if(!found) {
          measurement.data.forEach(mesh => {
            scene.remove(mesh);
            if(mesh.text_element) {
              mesh.text_element.remove();
            }
          })
        }
      })
    }

    if(!_.isEqual(prevProps.skins, this.props.skins)) {
        var currentVisibleSkin = this.props.skins.filter(skin => skin.visible);
        var texture = new THREE.TextureLoader().load(currentVisibleSkin[0].file);
        var wireMaterial = new THREE.MeshPhongMaterial({
            color: 0xFFFFFF,
            wireframe: true,
            map : texture
        });
        var material = new THREE.MeshPhongMaterial({
            map : texture
        });
        material.map.minFilter = THREE.LinearFilter
        mainPlane.material = material;
        wirePlane.material = wireMaterial;
    }

  }

  componentDidMount() {
      const { geojsons } = this.props;

      var popupDiv = document.createElement('div');
      popupDiv.id = "popup";
      document.body.append(popupDiv);

      var width  = window.innerWidth,
          height = window.innerHeight;

      var scene = new THREE.Scene();
      scene.background = new THREE.Color(0x333333)

      var camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 1000);
      camera.position.set(0, -50, 50);
      camera.updateMatrixWorld()

      var renderer = new THREE.WebGLRenderer();
      renderer.setSize(width, height);
      renderer.sortObjects = false

      var raycaster = new THREE.Raycaster();

      this.setState({scene, renderer, camera, raycaster});

      // Width is 303, height is 199
      // Total data points = 304 * 200
      var storedDataAsGrid = []

      var terrainLoader = new THREE.TerrainLoader();
      terrainLoader.load('./data/LiDAR_DEM_MIN.bin', data => {

          var dataArray = data;
          if (window.document.documentMode) { // IE compatibility
            dataArray = [];
            for(var position in data) {
              dataArray.push(data);
            }
          }
          dataArray.forEach((dataPoint, i) => {
            if(i%304 === 0) {
              storedDataAsGrid.push([dataPoint]);
            }
            storedDataAsGrid[storedDataAsGrid.length-1].push(dataPoint);
          })

          var geometry = new THREE.PlaneGeometry(91.1, 59.9, 303, 199);

          // Scale is 10
          for (var i = 0, l = geometry.vertices.length; i < l; i++) {
              geometry.vertices[i].z = (data[i] / 65535) * 10;
          }

          var currentVisibleSkin = this.props.skins.filter(skin => skin.visible);

          var texture = new THREE.TextureLoader().load(currentVisibleSkin[0].file);
          texture.anisotropy = renderer.capabilities.getMaxAnisotropy();

          var wireMaterial = new THREE.MeshPhongMaterial({
              color: 0xFFFFFF,
              wireframe: true,
              map : texture
          });
          var material = new THREE.MeshPhongMaterial({
              map : texture
          });
          material.map.minFilter = THREE.LinearFilter
          scene.add(new THREE.AmbientLight(0xeeeeee));

          var wirePlane = new THREE.Mesh(geometry, wireMaterial);
          var plane = new THREE.Mesh(geometry, material);
          scene.add(wirePlane);
          scene.add(plane);

          this.setState({wirePlane: wirePlane, mainPlane: plane})

          this.loadGeoJSONs(geojsons.filter(geojson => geojson.visible));

      });

      var controls = new THREE.TrackballControls(camera);

      document.getElementById('webgl').addEventListener( 'mousemove', this.sceneMouseMove, false );
      document.getElementById('webgl').addEventListener( 'click', this.sceneClick, false );
      document.getElementById('webgl').addEventListener( 'mousedown', this.sceneMouseDown, false );
      document.getElementById('webgl').addEventListener( 'mouseup', this.sceneMouseUp, false );
      controls.addEventListener( 'change', this.sceneScroll, false );
      document.getElementById('webgl').appendChild(renderer.domElement);

      render();

      function render() {
          controls.update();
          requestAnimationFrame(render);
          renderer.render(scene, camera);
      }

      // Geolocation currentUserLocation = [399425.288, 6030881.553]
      if(!navigator.geolocation) {
        console.log('geolocation not supported');
      } else {
        navigator.geolocation.watchPosition((position) => {
          var center = [399425.288, 6030881.553];
          var lngLat = [position.coords.longitude, position.coords.latitude];
          var reprojected = proj4('+proj=utm +zone=11 +ellps=GRS80 +datum=NAD83 +units=m +no_defs', lngLat);
          // var wgs84Center = [-118.54987418144773, 54.415708232810594];
          // var reprojectedtest = proj4('+proj=utm +zone=11 +ellps=GRS80 +datum=NAD83 +units=m +no_defs', wgs84Center);
          // console.log(reprojected, reprojectedtest)

          if(this.state.geolocatedCircles.length === 0) {
            var geometry = new THREE.CircleGeometry( 1, 10 );
            var material = new THREE.MeshBasicMaterial( { color: 0xffffff } );
            var circleOuter = new THREE.Mesh( geometry, material );
            var geometry = new THREE.CircleGeometry( 0.8, 10 );
            var material = new THREE.MeshBasicMaterial( { color: 0x0000ff } );
            var circleInner = new THREE.Mesh( geometry, material );
            circleOuter.position.set(reprojected[0]-center[0], reprojected[1]-center[1], 1);
            circleInner.position.set(reprojected[0]-center[0], reprojected[1]-center[1], 1.05);
            scene.add( circleOuter );
            scene.add( circleInner );
            this.setState({geolocatedCircles : [circleOuter, circleInner]})
          } else {
            this.state.geolocatedCircles[0].position.set(reprojected[0]-center[0], reprojected[1]-center[1], 1);
            this.state.geolocatedCircles[1].position.set(reprojected[0]-center[0], reprojected[1]-center[1], 1.05);
          }


        }, (error) => console.log(error));
      }
  }

  sceneMouseDown = (event) => {
    this.setState({dragging : true})
  }

  sceneMouseUp = (event) => {
    this.setState({dragging : false})
  }

  sceneScroll = (event) => {
    const { camera } = this.state;
    const { currentMeasurements } = this.props;
    currentMeasurements.forEach(measurement => {
      measurement.data.forEach(shape => {
        if(shape.text_element && shape.text_element.middleVector) {
          var newMiddlePoint = shape.text_element.middleVector;
          var projectedMiddlePoint = new THREE.Vector3(newMiddlePoint[0], newMiddlePoint[1], newMiddlePoint[2]).project(camera);
          projectedMiddlePoint.x = (projectedMiddlePoint.x + 1) * window.innerWidth/2;
          projectedMiddlePoint.y = (projectedMiddlePoint.y - 1) * window.innerHeight/2;
          shape.text_element.style.top = Math.abs(projectedMiddlePoint.y) + 'px';
          shape.text_element.style.left = Math.abs(projectedMiddlePoint.x) + 'px';
        }
      })
    })
  }

  sceneMouseMove = (event) => {
    const { scene, camera, raycaster, measurementBeingDrawn, dragging } = this.state;
    const { selectedMeasurement, geojsons } = this.props;

    var mousePosition = {};
    mousePosition.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    mousePosition.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
    document.body.style.cursor = 'auto';

    // Getting properties of items
    if(!measurementBeingDrawn) {
      var firstGeoJSONIntersection = false;
      if(!dragging) {
        raycaster.setFromCamera(mousePosition, camera);
        var intersects = raycaster.intersectObjects(scene.children, true);
        intersects.forEach(intersected => {
          if(!firstGeoJSONIntersection && intersected.object.geoJSON) {
            firstGeoJSONIntersection = intersected.object.geoJSON;
            var thisGeoJSONInfo = this.props.geojsons.filter(geojson => geojson.filename === intersected.object.geoJSONName)[0];
            if(thisGeoJSONInfo.visible) {
              document.body.style.cursor = 'pointer';
              var thisGroup = geoJSONGroups.filter(group => group.groupName === intersected.object.geoJSONName)[0]
              var objectsToChange = thisGroup.objects.filter(object => object.geoJSON.properties[thisGeoJSONInfo.propsToShow[0]] === intersected.object.geoJSON.properties[thisGeoJSONInfo.propsToShow[0]])
              objectsToChange.forEach(object => object.material.opacity = 1)
              if(intersected.object.hoverMaterial) {
                intersected.object.material = intersected.object.hoverMaterial;
                intersected.object.material.needsUpdate = true
              }
              var popupDiv = document.getElementById('popup');
              var htmlToAdd = '<table class="ui celled table">';
              thisGeoJSONInfo.propsToShow.forEach(prop => {
                htmlToAdd += `<tr><td><strong>${prop}</strong></td><td>${firstGeoJSONIntersection.properties[prop]}</td></tr>`;
              })
              htmlToAdd += '</table>';
              popupDiv.innerHTML = htmlToAdd;
              popupDiv.style.display = 'block';
              popupDiv.style.top = event.clientY-(popupDiv.offsetHeight+15) + 'px';
              popupDiv.style.left = event.clientX-(popupDiv.offsetWidth/2) + 'px';
            }
            }
        })
      }
      if(!firstGeoJSONIntersection) {
        var popupDiv = document.getElementById('popup');
        if(popupDiv.style.display !== 'none') {
          geoJSONGroups.forEach(group => {
            var thisGeoJSONInfo = geojsons.filter(geojson => geojson.filename === group.groupName)[0];
            if(thisGeoJSONInfo.visible) {
              if(!thisGeoJSONInfo.icon) {
                group.objects.forEach(object => object.material.opacity = 0.5)
              } else {
                group.objects.forEach(object => {
                  object.material = object.defaultMaterial;
                  object.material.needsUpdate = true;
                })
              }
            }
          })
        }
        popupDiv.style.display = 'none';
      }
    }

    // Checking measurement
    if(selectedMeasurement === 'Distance' && measurementBeingDrawn) {
      raycaster.setFromCamera(mousePosition, camera);
      var intersects = raycaster.intersectObjects(scene.children, true);
      if(intersects.length > 0) {
        measurementBeingDrawn.geometry.setDrawRange( 0, 2 );
        measurementBeingDrawn.geometry.attributes.position.array[3] = intersects[0].point.x;
        measurementBeingDrawn.geometry.attributes.position.array[4] = intersects[0].point.y;
        measurementBeingDrawn.geometry.attributes.position.array[5] = intersects[0].point.z;
        measurementBeingDrawn.geometry.attributes.position.needsUpdate = true

        var firstVector = new THREE.Vector3(measurementBeingDrawn.geometry.attributes.position.array[0], measurementBeingDrawn.geometry.attributes.position.array[1], measurementBeingDrawn.geometry.attributes.position.array[2]);
        var secondVector = new THREE.Vector3(measurementBeingDrawn.geometry.attributes.position.array[3], measurementBeingDrawn.geometry.attributes.position.array[4], measurementBeingDrawn.geometry.attributes.position.array[5]);
        var midPoint = turf.midpoint(
          turf.point([measurementBeingDrawn.geometry.attributes.position.array[0], measurementBeingDrawn.geometry.attributes.position.array[1]]),
          turf.point([measurementBeingDrawn.geometry.attributes.position.array[3], measurementBeingDrawn.geometry.attributes.position.array[4]])
        )
        var newMiddlePoint = [midPoint.geometry.coordinates[0], midPoint.geometry.coordinates[1], 0.5]
        var projectedMiddlePoint = new THREE.Vector3(newMiddlePoint[0], newMiddlePoint[1], newMiddlePoint[2]).project(camera);
        projectedMiddlePoint.x = (projectedMiddlePoint.x + 1) * window.innerWidth/2;
        projectedMiddlePoint.y = (projectedMiddlePoint.y - 1) * window.innerHeight/2;
        var center = [399425.288, 6030881.553]
        var scale = 0.1;
        measurementBeingDrawn.text_element.innerHTML = parseFloat((firstVector.distanceTo(secondVector) * 10).toFixed(2)) + ' m';
        measurementBeingDrawn.text_element.elevation_change = parseFloat(((measurementBeingDrawn.geometry.attributes.position.array[5] - measurementBeingDrawn.geometry.attributes.position.array[2]) * 10).toFixed(2)) + ' m';
        measurementBeingDrawn.text_element.start_point = ((measurementBeingDrawn.geometry.attributes.position.array[0]+center[0])).toFixed(2) + ', ' + ((measurementBeingDrawn.geometry.attributes.position.array[1]+center[1])).toFixed(2);
        measurementBeingDrawn.text_element.end_point = ((measurementBeingDrawn.geometry.attributes.position.array[3]+center[0])).toFixed(2) + ', ' + ((measurementBeingDrawn.geometry.attributes.position.array[4]+center[1])).toFixed(2);
        measurementBeingDrawn.text_element.style.top = Math.abs(projectedMiddlePoint.y) + 'px';
        measurementBeingDrawn.text_element.style.left = Math.abs(projectedMiddlePoint.x) + 'px';
        measurementBeingDrawn.text_element.style.marginLeft = -1*(measurementBeingDrawn.text_element.offsetWidth/2) + 'px';
        measurementBeingDrawn.text_element.style.marginTop = -1*(measurementBeingDrawn.text_element.offsetHeight/2) + 'px';
        measurementBeingDrawn.text_element.middleVector = newMiddlePoint;
      }
    }
  }

  sceneClick = (event) => {
    const { scene, camera, raycaster, measurementBeingDrawn, currentGroupBeingDrawn } = this.state;
    const { selectedMeasurement, currentMeasurements } = this.props;

    var mousePosition = {};
    mousePosition.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    mousePosition.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

    if(selectedMeasurement === 'Distance') {
      var newLineMaterial = new THREE.LineBasicMaterial( { color: 0xff0000, linewidth: 3 } );
      if(!measurementBeingDrawn) {
        raycaster.setFromCamera(mousePosition, camera);
        var intersects = raycaster.intersectObjects(scene.children, true);
        if (intersects.length > 0) {
          var newLineGeometry = new THREE.BufferGeometry().setFromPoints([ intersects[0].point ])
          var possiblePositions = new Float32Array( 3 * 3 )
          newLineGeometry.setAttribute('position', new THREE.BufferAttribute( possiblePositions, 3 ));
          newLineGeometry.setDrawRange( 0, 1 );
          newLineGeometry.dynamic = true;
          var newLine = new THREE.Line( newLineGeometry, newLineMaterial );
          newLine.geometry.attributes.position.array[0] = intersects[0].point.x;
          newLine.geometry.attributes.position.array[1] = intersects[0].point.y;
          newLine.geometry.attributes.position.array[2] = intersects[0].point.z;
          newLine.text_element = document.createElement('div');
          newLine.text_element.className = 'distance-text';
          document.body.append(newLine.text_element)
          scene.add(newLine);
          var geometry = new THREE.CircleGeometry( 0.3, 16 );
          var material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
          var startCircle = new THREE.Mesh( geometry, material );
          startCircle.position.set(intersects[0].point.x, intersects[0].point.y, intersects[0].point.z+0.5)
          scene.add(startCircle);
          var newGroupBeingDrawn = [startCircle];
          this.setState({measurementBeingDrawn : newLine, currentGroupBeingDrawn : newGroupBeingDrawn});
        }
      } else {
        raycaster.setFromCamera(mousePosition, camera);
        var intersects = raycaster.intersectObjects(scene.children, true);
        if (intersects.length > 0) {
          measurementBeingDrawn.geometry.setDrawRange( 0, 2 );
          measurementBeingDrawn.geometry.attributes.position.array[3] = intersects[0].point.x;
          measurementBeingDrawn.geometry.attributes.position.array[4] = intersects[0].point.y;
          measurementBeingDrawn.geometry.attributes.position.array[5] = intersects[0].point.z + 0.5;

          this.props.toggleMeasurement(false);
          var geometry = new THREE.CircleGeometry( 0.3, 16 );
          var material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
          var endCircle = new THREE.Mesh( geometry, material );
          endCircle.position.set(intersects[0].point.x, intersects[0].point.y, intersects[0].point.z + 0.5)
          scene.add(endCircle);

          var newGroupBeingDrawn = currentGroupBeingDrawn.map(group => group);
          newGroupBeingDrawn = newGroupBeingDrawn.concat([measurementBeingDrawn, endCircle]);
          var existingVisibleMeasurements = currentMeasurements.map(measurement => measurement);
          existingVisibleMeasurements.push({
            id : Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5),
            name : selectedMeasurement,
            data : newGroupBeingDrawn
          })
          this.setState({measurementBeingDrawn : false, currentGroupBeingDrawn : []})
          this.props.toggleMeasurement(false);
          this.props.setCurrentMeasurements(existingVisibleMeasurements)
        }
      }
    }
  }

  loadGeoJSONs = (geojsons) => {
    const { scene, raycaster, wirePlane } = this.state;

    geojsons.forEach((geojson, i) => {
      fetch('./data/geojsons/'+geojson.filename+'.geojson').then(resp => resp.json()).then(response => {
        var center = [399425.288, 6030881.553, 796.250]
        var minZ = 796.250;
        var scale = 0.1;
        var thisGroup = {
          groupName : geojson.filename,
          objects : []
        }
        if(response.features[0].geometry.type === 'Polygon') {
          response.features.forEach(feature => {
            feature.geometry.coordinates.forEach(shape => {
              var shapeAsLine = { type : "Feature", properties : {}, geometry : { type : "LineString", coordinates : shape }};
              var points = [];
              shape.forEach(point => {
                var modifiedX = (point[0]-center[0])*scale;
                var modifiedY = (point[1]-center[1])*scale;
                var modifiedZ = (point[2]-center[2])*scale;
                points.push(  new THREE.Vector3(modifiedX, modifiedY, modifiedZ + 1 ) );
              });
              var line = new MeshLine();
              line.setVertices(points);
              var material = new MeshLineMaterial({
                  color: geojson.color,
                  lineWidth : 0.2,
                  transparent : false,
                  opacity : 1
              })
              var plane = new THREE.Mesh(line, material);
              // For raycasting
              var newLineMaterial = new THREE.LineBasicMaterial( { color: geojson.color, transparent: false, opacity: 1 } );
              var newLineGeometry = new THREE.BufferGeometry().setFromPoints(points)
              var newLine = new THREE.Line( newLineGeometry, newLineMaterial );
              plane.geoJSONName = geojson.filename;
              plane.geoJSON = feature;
              newLine.geoJSONName = geojson.filename;
              newLine.geoJSON = feature;
              thisGroup.objects.push(plane);
              thisGroup.objects.push(newLine);
              scene.add(newLine);
              scene.add(plane);
            });
          })
          geoJSONGroups.push(thisGroup)
        }
        if(response.features[0].geometry.type === 'LineString') {
          response.features.forEach(feature => {
            var points = [];
            feature.geometry.coordinates.forEach(point => {
              var modifiedX = (point[0]-center[0])*scale;
              var modifiedY = (point[1]-center[1])*scale;
              var modifiedZ = (point[2]-center[2])*scale;
              points.push( new THREE.Vector3(modifiedX, modifiedY, modifiedZ + 1 ));
            });
            var line = new MeshLine();
            line.setVertices(points);
            var material = new MeshLineMaterial({
                color: geojson.color,
                lineWidth : 0.2,
                transparent : false,
                opacity : 1
            })
            var plane = new THREE.Mesh(line, material);
            var newLineMaterial = new THREE.LineBasicMaterial( { color: geojson.color, transparent: false, opacity: 1 } );
            var newLineGeometry = new THREE.BufferGeometry().setFromPoints(points)
            var newLine = new THREE.Line( newLineGeometry, newLineMaterial );
            plane.geoJSONName = geojson.filename;
            plane.geoJSON = feature;
            newLine.geoJSONName = geojson.filename;
            newLine.geoJSON = feature;
            thisGroup.objects.push(plane);
            thisGroup.objects.push(newLine);
            scene.add(newLine);
            scene.add(plane);
          })
          geoJSONGroups.push(thisGroup)
        }
        if(response.features[0].geometry.type.indexOf('Point') > -1) {
          var spriteMap = new THREE.TextureLoader().load( geojson.icon );
          var spriteMapHover = new THREE.TextureLoader().load( geojson.hoverIcon );
          response.features.forEach(feature => {
            var modifiedX = (feature.geometry.coordinates[0]-center[0])*scale;
            var modifiedY = (feature.geometry.coordinates[1]-center[1])*scale;
            var modifiedZ = (feature.geometry.coordinates[2]-center[2])*scale;
            var spriteMaterial = new THREE.SpriteMaterial( { map: spriteMap } );
            spriteMaterial.depthTest = false;
            var hoverMaterial = new THREE.SpriteMaterial( { map: spriteMapHover } );
            hoverMaterial.depthTest = false;
            var sprite = new THREE.Sprite( spriteMaterial );
            sprite.position.set(modifiedX, modifiedY, modifiedZ)
            sprite.geoJSONName = geojson.filename;
            sprite.defaultMaterial = spriteMaterial;
            sprite.hoverMaterial = hoverMaterial;
            sprite.geoJSON = feature;
            thisGroup.objects.push(sprite);
            scene.add( sprite );
          });
          geoJSONGroups.push(thisGroup)
        }
      })
    })
  }

  render() {
    return (
      <div id="webgl" />
    );
  }
}

export default Scene;
