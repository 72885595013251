import React, { useRef, useState } from 'react';
import '../App.css';

import Scene from './Scene';
import MainSidebar from './MainSidebar';

class App extends React.Component {

  /*

    To make all this dynamic:
     - We need to load with some kind of query string ID to fetch from a backend for THIS visualization
     - API payload would contain geoJSONs with XYZ, basic metadata (property, color, filename, propertyToShow) - must be same projections
     - API payload would contain .bin conversion of a DEM file
     - And the appropriate TIF that sits as a mask top of it (and would convert it to a smaller PNG)
     - API payload would contain metadata for the .bin file, namely the original DEM dimensions (width, height, elevation range),
       as well as the adjusted information (when bringing it down to 200 height)
        - This data would flow into the scene and replace values in there for heights and measurements and geographic references
     - API payload would contain files for download (or, links to those files)

     - In original setup, user would have to provide a DEM file, shapefiles or geoJSONs, downloadable files (links hosted elsewhere)
     - User would run a node process that would create the DEM metadata file, as well as the modified .bin (multi-step process),
       involves using gdal
        - gdalinfo
        - gdal_translate

====
    - Need to figure out how to get the Point Cloud in there as 3D ...

  */

  constructor(props) {
    super(props);
    this.state = {
      geojsons : [{
        filename : 'Construct_Shapes_Brown',
        name : 'Construction Shapes',
        color : 0x864300,
        visible : true,
        propsToShow : [ 'Name' ]
      },{
        filename : 'Dids_Green',
        name : 'Dispositions_Pembina',
        color : 0x006600,
        visible : true,
        propsToShow : [ 'disp_num' ]
      },{
        filename : 'Dids_Blue',
        name : 'Dispositions_Other',
        color : 0x214d5b,
        visible : true,
        propsToShow : [ 'disp_num' ]
      },{
        filename : 'Quarter_Section',
        name : 'Quarter Section',
        color : 0x868686,
        visible : true,
        propsToShow : [ 'DESCRIPTOR' ]
      },{
        filename : 'Welds_Red',
        name : 'Welds',
        icon : './img/red-circle.png',
        hoverIcon : './img/red-circle-hover.png',
        visible : true,
        propsToShow : [ 'NAME', 'ELEVATION' ]
      }],
      skins : [{
        name : 'Airphoto',
        file : './data/sample_airphoto.png',
        visible : true
      },{
        name : 'Bare Earth',
        file : './data/lidar_bare_earth.png',
        visible : false
      }
      // {
      //   name : 'Airphoto with Pipeline',
      //   file : './data/sample_airphoto_with_pipeline.png',
      //   visible : false
      // },{
      //   name : 'Pipeline Only',
      //   file : './data/PipeLine_PointCloud.png',
      //   visible : false
      // }
      ],
      imageLayers : [],
      measurementTypes : ['Distance'],
      files : [{
        name : 'Construction Plan PDF',
        file : './files/C18056 HWY 40 - Construction Plan Rev 2.pdf'
      },{
        name : 'Asbuilt Spreadsheet',
        file : './files/C18056 HWY 40 HDD-Asbuilt Spreadsheet.xlsx'
      },{
        name : '3d Laser Scan Model',
        file : 'https://cloud.pix4d.com/dataset/631542/model?shareToken=204de623-14d9-4656-bed1-2393833f0e36'
      }],
      selectedMeasurement : false,
      currentMeasurements : []
    }
  }

  toggleGeoJSON = (layerName) => {
    var newGeoJSONS = JSON.parse(JSON.stringify(this.state.geojsons)).map(geojson => {
      geojson.visible = geojson.filename === layerName ? !geojson.visible : geojson.visible;
      return geojson;
    });
    this.setState({geojsons : newGeoJSONS})
  }

  toggleImageLayer = (layerName) => {
    var newImageLayers = JSON.parse(JSON.stringify(this.state.imageLayers)).map(imageLayer => {
      imageLayer.visible = imageLayer.name === layerName ? !imageLayer.visible : imageLayer.visible;
      return imageLayer;
    });
    this.setState({imageLayers : newImageLayers})
  }

  toggleSkin = (skinName) => {
    var newSkins = JSON.parse(JSON.stringify(this.state.skins)).map(skin => {
      skin.visible = skin.name === skinName ? true : false;
      return skin;
    });
    this.setState({skins : newSkins})
  }

  removeMeasurement = (index) => {
    const { currentMeasurements } = this.state;
    var newMeasurements = currentMeasurements.filter((item, thisIndex) => thisIndex!==index);
    this.setState({currentMeasurements:newMeasurements});
  }

  render() {
    const { skins, imageLayers, geojsons, measurementTypes, files, selectedMeasurement, currentMeasurements } = this.state;

    return (
      <div>
        <MainSidebar
          toggleMeasurement={(measurement) => this.setState({selectedMeasurement:measurement})}
          toggleGeoJSON={this.toggleGeoJSON}
          toggleSkin={this.toggleSkin}
          toggleImageLayer={this.toggleImageLayer}
          selectedMeasurement={selectedMeasurement}
          measurementTypes={measurementTypes}
          removeMeasurement={this.removeMeasurement}
          currentMeasurements={currentMeasurements}
          files={files}
          skins={skins}
          imageLayers={imageLayers}
          geojsons={geojsons} />
        <Scene
          skins={skins}
          imageLayers={imageLayers}
          toggleMeasurement={(measurement) => this.setState({selectedMeasurement:measurement})}
          selectedMeasurement={selectedMeasurement}
          currentMeasurements={currentMeasurements}
          setCurrentMeasurements={(measurements) => this.setState({currentMeasurements : measurements})}
          geojsons={geojsons} />
      </div>
    );
  }
}

export default App;
